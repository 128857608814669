<template>
    <div>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
          <v-row>
            {{ text }}
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="snackbar = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
        <v-card class="pa-5">
            <v-card-title>Send us your information to contact you</v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-text-field
                    label="Full Name"
                    v-model="name"
                    prepend-icon="mdi-account"
                    :rules="[v => (!!v && v.length > 2) || 'Name is required at least 3 characters', v => /^[_A-z]*((-|\s)*[_A-z])*$/.test(v) || 'Name Must be letters only with no spaces']"
                    color="secondary"
                    id="mail-fullname"
                    >
                    </v-text-field>
                    <v-text-field
                    label="Email"
                    v-model="email"
                    type="email"
                    prepend-icon="mdi-email"
                    :rules="[v => !!v || 'Email is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                    color="secondary"
                    id="mail-email"
                    >
                    </v-text-field>
                    <phoneInput @update="assignPhone" />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn id="mail-submit" tile class="elevation-0" color="warning" :disabled="!valid" @click="sendMail">Send</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import phoneInput from '../components/mobile.vue'
import { bookFlightByMail, headersNoAuth } from '../links'

export default {
  props: ['flightId'],
  components: {
    phoneInput
  },
  data () {
    return {
      name: '',
      email: '',
      phone: null,
      snackbar: false,
      color: '',
      text: '',
      valid: true
    }
  },
  methods: {
    assignPhone (phone) {
      this.phone = phone
    },
    sendMail () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.phone && this.phone.valid) {
          const body = {
            search_id: this.$store.state.flightResults.search_id,
            flight_id: this.flightId,
            phone: this.phone.nationalNumber,
            countryIsoCode: this.phone.countryCode,
            name: this.name,
            email: this.email
          }
          this.$http.post(bookFlightByMail, body, { headers: headersNoAuth() }).then(response => {
            if (response.status === 200) {
              this.snackbar = true
              this.color = 'success'
              this.text = 'Your request has been submitted successfully'
              this.$refs.form.reset()
              this.$emit('success')
              this.$emit('close')
            } else {
              this.snackbar = true
              this.color = 'error'
              this.text = response.body.message
            }
          }, err => {
            this.snackbar = true
            this.color = 'error'
            this.text = err.body.message
          })
        } else {
          this.snackbar = true
          this.color = 'warning'
          this.text = 'Please add a phone number'
        }
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
      }
    }
  }
}
</script>
